import * as React from "react"
import { Link } from "gatsby"
import { cls } from "../../utils/helpers"

import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"

const posts = [
  {
    title: 'The beginnings of the Boundary Blog',
    href: 'the-beginnings-of-the-boundary-blog',
    category: {
      name: 'News',
      href: '#',
      color: 'bg-primary-400 text-primary-800'
    },
    description: 'Welcome to the Boundary Blog. Here you’ll find news, guides, changelogs and more. As Boundary is the ultimate CMS...',
    date: 'Aug 29, 2022',
    datetime: '2022-08-29',
    readingTime: '2 min',
  },
]

const BlogPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <div className="container mx-auto px-2">
        <Header />
        
        <section className="py-10">
          <div className="relative max-w-lg mx-auto lg:max-w-7xl">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-semibold sm:text-4xl sm:tracking-tight">
                Blog
              </h2>

              <p className="mt-3 text-xl text-primary-200 sm:mt-4 mb-8">
                Here you'll find how to guides, Boundary updates and general news.
              </p>

              <div className="w-14 h-0.5 bg-white rounded-full mx-auto"></div>
            </div>

            <div className="mt-16 grid gap-16 pt-12 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
              {posts.map((post) => (
                <div key={post.title}>
                  <div>
                    {/* <Link to={post.category.href} className="inline-block"> */}
                      <span
                        className={cls(`
                          ${post.category.color}
                          'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-semibold'
                        `)}
                      >
                        {post.category.name}
                      </span>
                    {/* </Link> */}
                  </div>

                  <Link to={post.href} className="block mt-4">
                    <p className="text-xl font-semibold">{post.title}</p>
                    <p className="mt-3 text-base text-primary-200">{post.description}</p>
                  </Link>

                  <div className="mt-6 flex items-center">
                    <div className="flex space-x-1 text-sm text-primary-400">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  )
}

export const Head = () => <>
  <title>Blog - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default BlogPage
